import { fileIcon } from "src/app/util/FileIcons";
import { IDriveItem } from "./document-folders.component";


export const largeDeepDataset: IDriveItem[] =
    [
        {
            isFolder: true,
            name: 'Project A',
            children: [
                {
                    isFolder: true,
                    name: 'Phase 1',
                    children: [
                        {
                            isFolder: true,
                            name: 'Documents',
                            children: [
                                {
                                    isFolder: false,
                                    name: 'report_final.docx',
                                    icon: fileIcon('docx')
                                },
                                {
                                    isFolder: false,
                                    name: 'summary_v2.pdf',
                                    icon: fileIcon('pdf')
                                },
                                {
                                    isFolder: true,
                                    name: 'Drafts',
                                    children: [
                                        {
                                            isFolder: false,
                                            name: 'draft1.doc',
                                            icon: fileIcon('doc')
                                        },
                                        {
                                            isFolder: false,
                                            name: 'draft2.docx',
                                            icon: fileIcon('docx')
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            isFolder: true,
                            name: 'Images',
                            children: [
                                {
                                    isFolder: false,
                                    name: 'logo_v1.png',
                                    icon: fileIcon('png')
                                },
                                {
                                    isFolder: false,
                                    name: 'banner_updated.jpg',
                                    icon: fileIcon('jpg')
                                }
                            ]
                        }
                    ]
                },
                {
                    isFolder: true,
                    name: 'Phase 2',
                    children: [
                        {
                            isFolder: true,
                            name: 'Research',
                            children: [
                                {
                                    isFolder: false,
                                    name: 'study_2023.pdf',
                                    icon: fileIcon('pdf')
                                },
                                {
                                    isFolder: false,
                                    name: 'data_analysis.xlsx',
                                    icon: fileIcon('xlsx')
                                }
                            ]
                        },
                        {
                            isFolder: true,
                            name: 'Archive',
                            children: [
                                {
                                    isFolder: true,
                                    name: 'Old Reports',
                                    children: [
                                        {
                                            isFolder: false,
                                            name: 'old_report_final.xlsx',
                                            icon: fileIcon('xlsx')
                                        },
                                        {
                                            isFolder: false,
                                            name: 'archive_2022.pdf',
                                            icon: fileIcon('pdf')
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            isFolder: true,
            name: 'Project B',
            children: [
                {
                    isFolder: true,
                    name: 'Meeting Notes',
                    children: [
                        {
                            isFolder: false,
                            name: 'meeting_notes_v1.txt',
                            icon: fileIcon('txt')
                        },
                        {
                            isFolder: true,
                            name: 'Notes Archive',
                            children: [
                                {
                                    isFolder: false,
                                    name: 'meeting_notes_old.txt',
                                    icon: fileIcon('txt')
                                },
                                {
                                    isFolder: false,
                                    name: 'meeting_summary.pdf',
                                    icon: fileIcon('pdf')
                                }
                            ]
                        }
                    ]
                },
                {
                    isFolder: true,
                    name: 'Research Data',
                    children: [
                        {
                            isFolder: false,
                            name: 'research_paper.docx',
                            icon: fileIcon('docx')
                        },
                        {
                            isFolder: true,
                            name: 'Supplementary',
                            children: [
                                {
                                    isFolder: false,
                                    name: 'supplementary_material.pdf',
                                    icon: fileIcon('pdf')
                                },
                                {
                                    isFolder: true,
                                    name: 'Figures',
                                    children: [
                                        {
                                            isFolder: false,
                                            name: 'figure1.png',
                                            icon: fileIcon('png')
                                        },
                                        {
                                            isFolder: false,
                                            name: 'figure2.jpg',
                                            icon: fileIcon('jpg')
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            isFolder: true,
            name: 'Miscellaneous',
            children: [
                {
                    isFolder: false,
                    name: 'file1.msg',
                    icon: fileIcon('msg')
                },
                {
                    isFolder: true,
                    name: 'Temporary Files',
                    children: [
                        {
                            isFolder: false,
                            name: 'temp1.txt',
                            icon: fileIcon('txt')
                        },
                        {
                            isFolder: true,
                            name: 'Subfolder Temp',
                            children: [
                                {
                                    isFolder: false,
                                    name: 'temp2.docx',
                                    icon: fileIcon('docx')
                                },
                                {
                                    isFolder: false,
                                    name: 'temp3.pdf',
                                    icon: fileIcon('pdf')
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

import { Component, OnInit, inject } from "@angular/core";
import { DatalexMsalService } from "src/app/services/datalex-msal.service";
import { IAzUser, IMessageLimited } from "./GraphModels";
import { IgxButtonDirective, IgxDialogComponent, IgxGridModule, IgxNavigationDrawerModule, IgxSplitterModule } from "@infragistics/igniteui-angular";
import { Router, RouterLink, RouterOutlet } from "@angular/router";
import { deleteFile, getDrives, getGroups, getPermissions, getUsers, putPermissions, uploadFileToDrive, uploadFileToPersonalDrive } from "./graphMethods";
import { applicationURL } from "src/app/util/ApplicationURLs";
import { DocumentSearchComponent } from "../../UI/document-search/document-search.component";
import { DatalexClient, IDocumentLimitedBE } from "@datalex-software-as/datalex-client";
import { DocumentSearchDirective } from "src/app/directives/document-search.directive";
import { HourRegistrationMainGroupEditorComponent } from "../../UI/register-editors/editors/hour-registration-main-group-editor/hour-registration-main-group-editor.component";
import { HourRegistrationTypeEditorComponent } from "../../UI/register-editors/editors/hour-registration-type-editor/hour-registration-type-editor.component";
import { catchError } from "rxjs";
import { DocumentFoldersComponent } from "../../UI/document-folders/document-folders.component";
import { MoneyLaunderingStatusComponent } from "../../UI/reports/money-laundering/money-laundering-status/money-laundering-status.component";


const _PORT_ = 3000;
const _PROTOCOL_ = 'http';



@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss'],
  imports: [IgxSplitterModule, IgxNavigationDrawerModule, RouterOutlet, DocumentSearchComponent, DocumentSearchDirective, IgxDialogComponent, IgxGridModule, IgxButtonDirective, HourRegistrationMainGroupEditorComponent, HourRegistrationTypeEditorComponent, DocumentFoldersComponent, MoneyLaunderingStatusComponent],
  standalone: true,
})
export class TestPageComponent implements OnInit {
  dlxClient = inject(DatalexClient);
  ngOnInit(): void { }

  onDocumentClick(driveItem: any) {
    console.log(driveItem);
  }


}


// API_URL = `${_PROTOCOL_}://localhost:${_PORT_}/api/`;
// dlxMsal = inject(DatalexMsalService);
// router = inject(Router)

// messages: IMessageLimited[] = [];
// selectedMessage: IMessageLimited | null = null;

// public navItems = [
//   { icon: 'inbox', name: "inbox", text: 'Innboks' },
//   { icon: 'drafts', name: "drafts", text: 'Kladd' },
//   { icon: 'send', name: "sent", text: 'Sendt' }
// ];

// public selected = 'Innboks';

// public navigate(item: { name: string, text: string }) {
//   this.selected = item.text;
//   this.router.navigate([`test/${item.name}`]);
// }

// driveId: string = "";
// itemId: string = "";
// msusers: any = [];
// selectedUsers: any = [];

// driveItem: any;

// ngOnInit() {
// }

// getDrives() {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       getDrives(this.API_URL, token).then((data) => {
//         console.log(data)
//       })

//     }

//   })
// }

// uploadFile(e: HTMLInputElement) {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       uploadFileToPersonalDrive(this.API_URL, token, e).then((data) => {
//         console.log(data)
//         this.itemId = data.id;
//         this.driveId = data.parentReference.driveId
//       })

//     }

//   })
// }
// uploadFile2(e: HTMLInputElement) {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       uploadFileToDrive(this.API_URL, token, e).then((data) => {
//         console.log(data)
//         this.itemId = data.id;
//         this.driveId = data.parentReference.driveId

//         this.driveItem = data;
//       })

//     }

//   })
// }

// deleteFile(){
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       deleteFile(this.API_URL, token, this.itemId).then((data) => {
//         console.log(data)
//       })

//     }

//   })
// }
// openFile(){
//   console.log(this.driveItem)
//   window.open(`${applicationURL.ms_word_rw}https://datalexsoftwareas.sharepoint.com/SondreTemp/CaseNumber/DocumentNumber/${this.driveItem.name}`, '_blank');
// }

// getDriveItemPermissions() {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       getPermissions(this.API_URL, token, this.driveId, this.itemId).then((data) => {
//         console.log(data)
//       })

//     }

//   })
// }
// putDriveItemPermissions() {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       putPermissions(this.API_URL, token, this.driveId, this.itemId, [this.selectedUsers[0].id]).then((data) => {
//         console.log(data)
//       })

//     }

//   })
// }
// getMicrosoftUsers() {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       getUsers(this.API_URL, token).then((data) => {
//         this.msusers = data.value;
//       })

//     }

//   })
// }
// getMicrosoftGroups() {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       getGroups(this.API_URL, token).then((data) => {
//         data.value.forEach((group: any) => {
//           if (group.onPremisesDomainName === null) {
//             console.log(group)
//           }
//         })
//       })

//     }

//   })
// }